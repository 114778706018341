// extracted by mini-css-extract-plugin
export var contact = "zabka-contest-layout-module--contact--4bb99";
export var container = "zabka-contest-layout-module--container--32e90";
export var content = "zabka-contest-layout-module--content--3a107";
export var errorBlink = "zabka-contest-layout-module--error-blink--4a970";
export var hasHeader = "zabka-contest-layout-module--has-header--bbf1b";
export var header = "zabka-contest-layout-module--header--7c78f";
export var headerContent = "zabka-contest-layout-module--header-content--5ef47";
export var layout = "zabka-contest-layout-module--layout--949ab";
export var left = "zabka-contest-layout-module--left--e863d";
export var right = "zabka-contest-layout-module--right--4b368";
export var thanks = "zabka-contest-layout-module--thanks--cf2e5";