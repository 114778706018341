import React, { useState } from 'react';
import { Form, Formik, ErrorMessage, Field, FormikConfig } from 'formik';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useMutation } from "react-query";

import { container, header, title, info, icon, input, error, loading, button, fields, full, success } from './zabka-contest-winner-form.module.scss';
import WinnerIcon from "../../assets/images/svg/winner.svg";
import pagesContext from "../../config/pages-context";
import {
    getInitialValues,
    getValidationSchema,
    IZabkaContestWinnerValues
} from "../../formik/zabka-contest-form-winner.config";
import { getZabkaContestCheckToken, postZabkaContestWinner } from "../../api-ssr/zabka-contest";
import useContestTokenCheck from "../../hooks/use-contest-token-check";

import Button from "../atoms/button";
import Captcha from "../atoms/captcha";
import Loader from "../atoms/loader";

const RECAPTCHA = process.env.RECAPTCHA;

interface IZabkaContestWinnerFormProps {
    className?: string;
}

const ZabkaContestWinnerForm: React.FC<IZabkaContestWinnerFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isTokenCheckLoading, setIsTokenCheckLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync, isSuccess } = useMutation(postZabkaContestWinner);

    const onLoad = () => {
        setIsTokenCheckLoading(false);
    };

    const token = useContestTokenCheck(language, getZabkaContestCheckToken, redirectPages, onLoad);

    const handleSubmit: FormikConfig<IZabkaContestWinnerValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
        } catch(error: any) {
            const errorMessage = error?.messages?.map((message: any) => message.content).join(' ')
            alert(errorMessage || error);
        } finally {
            setIsLoading(false);
        }
    }

    if (isTokenCheckLoading) {
        return <Loader />;
    }

    if (isSuccess) {
        return (
            <div className={`${container} ${success}`}>
                <div className={header}>
                    <WinnerIcon className={icon} />
                    <h1 className={title}>{t('zabka.contest.winner.form.success.title')}</h1>
                    <p className={info}>{t('zabka.contest.winner.form.success.info')}</p>
                </div>
            </div>
        );
    }

    return (
        <Formik
            initialValues={getInitialValues(token)}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
            enableReinitialize={true}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <div className={header}>
                        <WinnerIcon className={icon} />
                        <h1 className={title}>{t('zabka.contest.winner.form.title')}</h1>
                        <p className={info}>{t('zabka.contest.winner.form.info')}</p>
                    </div>
                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('zabka.contest.winner.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('zabka.contest.winner.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <div className={full}>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('zabka.contest.winner.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                type="tel"
                                name="phone"
                                placeholder={t('zabka.contest.winner.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('zabka.contest.winner.form.street.placeholder')}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="houseNumber"
                                placeholder={t('zabka.contest.winner.form.houseNumber.placeholder')}
                            />
                            <ErrorMessage name="houseNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t('zabka.contest.winner.form.apartmentNumber.placeholder')}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('zabka.contest.winner.form.zipCode.placeholder')}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="city"
                                placeholder={t('zabka.contest.winner.form.city.placeholder')}
                            />
                            <ErrorMessage name="city" className={error} component="p" />
                        </div>
                    </div>
                    <Button type="submit" className={button}>{t('zabka.contest.winner.form.submit')}</Button>
                    {RECAPTCHA && (
                        <Captcha siteKey={RECAPTCHA} />
                    )}
                </Form>
            )}
        </Formik>
    );
};

const redirectPages = {
    default: pagesContext.zabkaContest.slug,
    expired: pagesContext.zabkaContestExpired.slug,
};

export default ZabkaContestWinnerForm;