import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export interface IZabkaContestWinnerValues {
    token: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    street: string;
    houseNumber: string;
    apartmentNumber: string;
    zipCode: string;
    city: string;
    recaptcha: string;
}

export function getInitialValues(token: string): IZabkaContestWinnerValues {
    return {
        token: token,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        zipCode: '',
        city: '',
        recaptcha: '',
    };
};

export function getValidationSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape({
        firstname: Yup.string().required(t('form.required.error')),
        lastname: Yup.string().required(t('form.required.error')),
        email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
        phone: Yup.string().required(t('form.required.error')),
        street: Yup.string().required(t('form.required.error')),
        houseNumber: Yup.string().required(t('form.required.error')),
        apartmentNumber: Yup.string().max(16, t('form.apartmentNumber.length')),
        zipCode: Yup.string().required(t('form.required.error')),
        city: Yup.string().required(t('form.required.error')),
        recaptcha: Yup.string().required(),
    });
}
