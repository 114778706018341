// extracted by mini-css-extract-plugin
export var button = "zabka-contest-winner-form-module--button--8c451";
export var container = "zabka-contest-winner-form-module--container--a527d";
export var error = "zabka-contest-winner-form-module--error--afa1b";
export var errorBlink = "zabka-contest-winner-form-module--error-blink--f5e33";
export var fields = "zabka-contest-winner-form-module--fields--8f56c";
export var full = "zabka-contest-winner-form-module--full--f5276";
export var header = "zabka-contest-winner-form-module--header--5467c";
export var icon = "zabka-contest-winner-form-module--icon--296ef";
export var info = "zabka-contest-winner-form-module--info--cc1c4";
export var input = "zabka-contest-winner-form-module--input--f73e0";
export var loading = "zabka-contest-winner-form-module--loading--1e50f";
export var success = "zabka-contest-winner-form-module--success--3300b";
export var title = "zabka-contest-winner-form-module--title--bf9e2";